import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";

export const frontmatter = {
  title: "Día 38",
  week: "Semana 6",
  month: "abr",
  day: "20",
  monthNumber: "04",
  date: "2020-04-20",
  path: "/cronologia/semana-06#dia-20-abr",
};
const Day38 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={"2020-04-20"} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 4.266 nuevos positivos y 399 personas fallecidas.
          Permanecen hospitalizadas 76.512 personas, 442 más que el día
          anterior, y 3.230 reciben el alta. El número de casos ingresados en la
          UCI asciende a 7.698, lo que supone un aumento de 30 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,4 % y la tasa de recuperados del 40,3 %.
        </ModText>
        <ModGraph
          name="20-04-hospitalizados-ar"
          alt="Hospitalizados por coronavirus aragón por provincias"
        />
        <ModText>
          Aragón ha experimentado un incremento de hospitalizados concentrado en
          la provincia de Zaragoza. Comunidades con una fuerte diferencia de
          densidad de población muestran un fuerte contraste en sus evoluciones
        </ModText>
        <ModText>
          Por otra parte, Zaragoza es un centro logístico importante, con mucho
          tráfico de mercancía y gente, lo que seguramente influyó en las
          primeras etapas de la crisis.
        </ModText>
        <ModCovidHighlight>
          20% de los infectados son personal sanitario
        </ModCovidHighlight>
        <ModText>
          Un <strong>19,6%</strong> de los casos notificados a SiViES{" "}
          <strong>son personal sanitario </strong>
          (dato calculado sobre los casos que tenían información sobre esta
          variable), siendo significativamente mayor este porcentaje entre las
          mujeres que entre los hombres (27,2 vs 11%).
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day38;
